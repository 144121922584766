import React from 'react'

import { DividerHeading, Gallery, HomeSplash, ItsAllHere } from 'components'
import { useMobileDetect } from 'hooks'
import { HomeGalleryImages } from 'helpers'

const Home = () => {
  const { isMobile } = useMobileDetect()

  return (
    <main style={{ background: '#111', height: 'auto' }}>
      <HomeSplash />
      <ItsAllHere isMobile={isMobile} />
      <DividerHeading title1='an actual' title2='virtual 3d platform' />
      <Gallery columns={4} rows={4} images={HomeGalleryImages} heading={{ number: '02', title: 'Gallery' }} />
    </main>
  )
}

export default Home
